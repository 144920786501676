<!--
### Basic UI card
 -->
<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";

  type CardProps = HTMLAttributes<HTMLDivElement>;

  const { children, class: className, ...props }: CardProps = $props();
</script>

<style>
  .card {
    padding: var(--size-8);
    border-radius: var(--radius-xl);
    box-shadow: var(--shadow-sm);
    background: var(--color-bg);
    border: 1px solid var(--color-border-secondary);
  }
</style>

<div class="card {className ?? ''}" {...props}>
  {@render children?.()}
</div>
